






import Vue from 'vue';
export default Vue.extend({
    props: {
        "narrow": {
            type: Boolean as () => boolean,
        },
    },
});
